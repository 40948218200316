<template>
  <v-container id="database" fluid tag="section">
    <v-row>
      <v-col>
        <a href="http://18.182.7.84:8818/" target="_blank" >Mongodb</a>
      </v-col>
      <v-col>
        <a href="http://18.182.7.84:8819/" target="_blank" >Mysql</a>
      </v-col>
      <v-col>
        <a href="http://18.182.7.84:8820/" target="_blank" >Redis</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
